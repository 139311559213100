// If you want to override variables do it here
// @import "utilities";

@import "@angular/cdk/overlay-prebuilt.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap");
// @import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@font-face {
  font-family: "Lexend Deca";
  src: url("/assets/fonts/Lexend_Deca/LexendDeca-VariableFont_wght.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

// If you want to add something do it here
@import "custom";

html,
body {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: "Mulish", Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

a {
  text-decoration: none;
}
p {
  margin-bottom: 0;
}
