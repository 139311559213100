.ant-tabs {
  &-tab {
    padding: 12px;

    // &-active {
    //   background-color: @primary-hover-color;
    // }
  }

  &-top > &-nav {
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 25px 25px -10px;
    margin-bottom: unset;
    &::before {
      border-bottom: unset;
    }
  }
}
