@dd-prefix: ~"ant-dropdown";

.ant-dropdown {
  &-menu {
    border-radius: 0.5rem;
    padding: 10px 0;

    &-item {
      padding: 8px 14px;
      min-width: 150px;

      &-danger {
        &:hover {
          background-color: #ffebeb !important;
          color: @error-color !important;
        }

        a {
          color: @error-color;

          &:hover {
            color: @error-color;
          }
        }
      }

      &:hover {
        background-color: @primary-hover-color;
      }
    }
  }
}
