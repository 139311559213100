.ant-input {
  border-radius: 0.5rem;

  &:focus,
  &-focused {
    // border: none;
    box-shadow: none;
  }

  &-group {
    // .ant-input {
    //   &:focus,
    //   &:hover {
    //     border: none;
    //   }
    // }

    &-addon {
      padding: 0;
      border: none;
      min-width: 60px;
      border-radius: 10px;
      overflow: hidden;

      .ant-btn-icon-only.ant-btn-lg {
        border-radius: 10px 10px 10px 10px;
      }
    }
  }

  &-affix-wrapper {
    border-radius: 0.5rem;

    // &:not(&-disabled):hover {
    //   border: unset;
    // }

    &:focus,
    &-focused {
      /* border: unset; */
      box-shadow: unset;
    }
  }
}
