@m-prefix: ~"ant-menu";

.ant-menu {
  &-inline,
  &-vertical {
    border: none;

    &-collapsed > .ant-menu-item,
    &-collapsed > .ant-menu-submenu {
      display: flex;
      align-items: center;
      justify-content: center;
      a,
      div {
        display: flex;
        justify-content: center;

        > span {
          display: none;
        }
      }
    }

    > .@{m-prefix}-item {
      height: 50px;
      line-height: 50px;
    }
  }

  &-item {
    &-danger {
      &:hover {
        background-color: #ffebeb !important;
      }

      a {
        color: @error-color;

        &:hover {
          color: @error-color;
        }
      }
    }

    &:hover {
      background-color: @secondary-hover-bg-color;
    }
  }

  &-submenu {
    &:hover {
      background-color: @secondary-hover-bg-color;
    }

    &-selected {
      background-color: @menu-item-active-bg !important;
    }
  }
}
