@p-prefix: ~"ant-pagination";

.@{p-prefix} {
  ul {
    display: flex;

    .@{p-prefix}-prev {
      display: flex;
      align-items: center;
      margin-right: 10px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .@{p-prefix}-next {
      display: flex;
      align-items: center;
      padding-left: 5px;
      padding-right: 5px;
    }

    .@{p-prefix}-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      padding-left: 5px;
      padding-right: 5px;

      &-active {
        background-color: @secondary-color;
        border-color: @secondary-color;
        a {
          color: #fff;
        }
      }

      &:hover {
        border-color: @secondary-color;
      }
    }

    .@{p-prefix}-disabled,
    .@{p-prefix}-disabled:hover {
      .ng-star-inserted {
        color: #00000040;
      }
    }
  }
}
