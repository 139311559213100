@s-prefix: ~"ant-select";

.ant-select {
  &-selector {
    border-radius: 0.5rem !important;
  }

  &-single:not(&-customize-input) &-selector {
    border-radius: 0.5rem;
  }

  &-focused:not(&-disabled)&:not(&-customize-input) &-selector {
    box-shadow: none;
  }

  &-multiple&-lg &-selection-item {
    height: unset;
    min-height: 32px;
  }

  &-multiple &-selector {
    padding: 5px 10px;

    .@{s-prefix}-selection-item {
      padding-inline-start: 0px;
      padding-inline-end: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 5px;
    }
  }

  &-item {
    padding: 8px 14px;

    &-danger {
      &:hover {
        background-color: #ffebeb !important;
        color: @error-color !important;
      }

      a {
        color: @error-color;

        &:hover {
          color: @error-color;
        }
      }
    }

    &:hover {
      background-color: @primary-hover-color;
    }

    &-option-selected:not(&-option-disabled) {
      color: @primary-color;
      background-color: @primary-active-color;
    }
  }

  &-dropdown {
    .@{s-prefix}-item-option {
      min-width: 150px;
    }
  }
}
