.ant-table {
  &-thead {
    > tr {
      > th {
        background: #f6f6f6;
        color: #202224;
        font-weight: 600;

        &:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
          ):not([colspan])::before {
          content: unset;
        }
      }
    }
  }

  &-tbody {
    > tr {
      &:nth-child(even) {
        background-color: #f6f6f6b2;
      }
      &:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }

  &-pagination.ant-pagination {
    padding: 0 30px;
  }
}
