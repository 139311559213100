.ant-modal {
  top: 0px;
  padding: 0px;

  &-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-body {
    max-height: 90vh;
    overflow: auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  &-header {
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: unset;
    padding-top: unset;
    padding-bottom: 10px;
    border-bottom: 1px solid #f1f1f1;
  }

  &-footer {
    padding-left: 30px;
    padding-right: 30px;
  }

  &-content {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
  }
}
