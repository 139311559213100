// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: #2b4e4c;
@secondary-color: #178ca6;
@primary-active-color: #e0ebea;
@primary-hover-color: #eff5f5;
@secondary-active-bg-color: #d5eef5;
@secondary-hover-bg-color: #f0fcff;
@danger-color: #be2921;

@border-radius-base: 0.5rem;

@btn-danger-bg: @danger-color;
@btn-danger-border: @danger-color;

@avatar-bg: @primary-color;

@layout-header-background: white;
@layout-header-height: 66px;

@menu-item-height: 55px;
@menu-item-active-bg: @secondary-active-bg-color;
@menu-highlight-color: @secondary-color;

@popover-padding-horizontal: "0px";

@tooltip-bg: @primary-color;

@dropdown-selected-color: @primary-color;
@dropdown-selected-bg: @primary-active-color;

// @tabs-highlight-color: @primary-active-color;
// @tabs-hover-color: @primary-hover-color;
@tabs-active-color: @primary-active-color;

@import "./date-picker.less";
@import "./select.less";
@import "./input.less";
@import "./modal.less";
@import "./collapse.less";
@import "./button.less";
@import "./tabs.less";
@import "./dropdown.less";
@import "./table.less";
@import "./pagination.less";
@import "./avatar.less";
@import "./picker.less";
@import "./input-number.less";
@import "./menu.less";
@import "./popover.less";
@import "./card.less";
