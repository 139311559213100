.ant-collapse {
  border-radius: 0.5rem;

  &-item:last-child > &-content {
    border-radius: 0 0 0.5rem 0.5rem;
  }

  & > &-item:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
  }
}
