.ant-btn {
  &:not(&-circle) {
    height: 40px;
    border-radius: 0.5rem;
  }

  &-lg.ck {
    height: unset;
    min-height: 40px;
  }

  &-lg {
    height: 48px;
  }

  &-dangerous&-primary,
  &-dangerous&-primary:hover,
  &-dangerous&-primary:focus {
    border-color: #be2921;
    background-color: #be2921;
  }

  &-dangerous&-link[disabled],
  &-dangerous&-link[disabled]:hover,
  &-dangerous&-link[disabled]:focus,
  &-dangerous&-link[disabled]:active {
    color: #e2b6b2;
  }
}
