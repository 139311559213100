.ant-picker {
  border-radius: 0.5rem;

  &:focus,
  &-focused {
    // border: none;
    box-shadow: none;
  }

  &-group {
    .ant-input {
      &:focus,
      &:hover {
        border: none;
      }
    }
  }
}
